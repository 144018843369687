import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'

import Block from '../components/block'
import SEO from '../components/seo'
import Layout from '../components/layout'

import '../graphql/pricing'

const PricingPage = ({ data }) => {
  const {
    sanityLayoutDefault: { title: pageTitle, seo, content }
  } = data

  return (
    <Layout>
      <SEO title={pageTitle} seo={seo} />
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs={12} sm={12} md={8} lg={4}>
            <section className="pricing">
              <article className="main--excerpt">
                <h2>
                  <Block data={content?.title} />
                </h2>
                <h3>
                  <Block data={content?.subtitle} />
                </h3>
                <Block data={content?.description} tag="p" />
              </article>
              {content?.ctaEnable && (
                <Link to={content?.ctaLink} className="btn btn-block btn-outline-main text-uppercase margin-top-50">
                  {content?.ctaText}
                </Link>
              )}
            </section>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default PricingPage
export const query = graphql`
  query PricingQuery {
    sanityLayoutDefault(slug: {current: {eq: "pricing"}}) {
      ...PricingParts
    }
  }
`
