import React from 'react'
import PropTypes from 'prop-types'

import Header from '../header'

const Layout = ({ children }) => {
  return (
    <>
      <section className="main--layout main--bg-green">
        <Header />
        <main>{children}</main>
      </section>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
